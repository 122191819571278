import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PeticionesLayout, NuevoClienteLayout } from '../../../theme/Layout'
import useMode from '../../../utils/hooks/useMode'
import useNuevaPeticion from './hooks/useNuevapeticion'
import InputComponent from '../../../utils/Components/Inputs/InputBuscador'
import useClientes from '../../hooks/useClientes'
import ModalComponent from '../../../utils/Components/modal/ModalComponent'
import useModal from '../../../utils/Components/modal/usemodal'
import useDirecciones from '../../hooks/useDirecciones'
import useDatosOnsite from '../../hooks/useDatosOnsite'
import { useAuth } from '../../../context/authcontext'
import useAutocompletecp from '../../hooks/useAutocompletecp'
import moment from 'moment'
import 'moment-timezone';
import { useNavigate } from "react-router-dom";
import UsePeticiones from '../../hooks/UsePeticiones'
// TABLA
import TablaSinPaginador from '../../../utils/Components/Tablas/TablaSinpaginador/TablaSinPaginador'
// IMAGENES
import imgSucursal from '../../../assets/imagenes/sucursalnvo.webp';
import imgOnsite from '../../../assets/imagenes/Onsite.webp'
import imgCheckbox from '../../../assets/imagenes/checkbox.svg';
import imgCheckboxoff from '../../../assets/imagenes/checkboxoff.svg';
import imgPX from '../../../assets/imagenes/px.svg';
import imgDHL from '../../../assets/imagenes/dhl.svg';
import imgUPS from '../../../assets/imagenes/ups.svg';
import imgFEDEX from '../../../assets/imagenes/fedex.svg';
import imgCaja from '../../../assets/imagenes/iconCaja.webp';
import imgSobre from '../../../assets/imagenes/iconoSobre.webp';
import imgTarima from '../../../assets/imagenes/iconoTarima.webp';
import AvatarNuevo from '../../../assets/imagenes/AvatarInicio.webp'
import imgAsignar from '../../../assets/imagenes/userCheck.svg';
import imgSinAsignar from '../../../assets/imagenes/userDontCheck.png';
import imgFlecha from '../../../assets/iconos/flechaMercancia.svg';
import imgFlechaOff from '../../../assets/iconos/flechaMercanciaoff.svg';
import MapaclientePeticion from '../../Mapa/MapaclientePeticion';
import NuevoIcon from '../../../assets/iconos/NuevoIcon';
import EditarIcon from '../../../assets/iconos/EditarIcon.svg';
import EliminarIcon from '../../../assets/iconos/EliminarIcon.svg';
import imgEntrega from '../../../assets/iconos/entrega.png'
import imgRecoleccion from '../../../assets/iconos/recoleccion.png'
import imgCobranza from '../../../assets/iconos/cobranza.png'
import imgBanco from '../../../assets/iconos/banco.png'
import imgOtro from '../../../assets/iconos/otro.png'
import imgRecoleccionActivo from '../../../assets/iconos/recoleccionActivo.png'
import imgEntregaActivo from '../../../assets/iconos/entregaActivo.png'
import imgCobranzaActivo from '../../../assets/iconos/cobranzaActivo.png'
import imgBancoActivo from '../../../assets/iconos/bancoActivo.png'
import imgOtroActivo from '../../../assets/iconos/otroActivo.png'
import prev from '../../../assets/iconos/prev.png'
import next from '../../../assets/iconos/next.png'
import banderaMX from '../../../assets/iconos/banderaMX.png'
import Loader from '../../../utils/Components/Loader/Loader'
import useErrorAlert from '../../../utils/hooks/useErrorAlert';
import { color } from 'framer-motion';

const Nuevapeticion = () => {
    let dimension = window.innerWidth;
    const navigate = useNavigate();
    const { user, RecolectoresMapa } = useAuth()
    const { AzulFuerteLight, Darkmode, Blanco } = useMode()
    const { HanlderMensajeCorrecto } = useErrorAlert()
    const {
        HanlderCambioOrigen,
        OrigenSelecionado,
        ClienteBuscador,
        HanlderClienteBuscador,
        HanlderSeleccionarCliente,
        ClienteSeleccionado,
        // FUNCIONES ONSITE
        SelectMercanciaOnsite,
        HandlerSelectMercanciaOS,
        HandlerSelectMercanciaOSAll,
        HandlerDeseleccionar,

        // NUEVA PETICION
        // NUEVA DIRECCION
        PaisNueva,
        CalleNueva,
        NumeroExtNueva,
        NumIntNueva,
        CpNueva,
        ColoniaNueva,
        CiudadNueva,
        MunicipioNueva,
        EstadoNueva,
        CorreoNueva,
        TelefonoNueva,
        HandlerCambioPaisNueva,
        HandlerCambioCalleNueva,
        HandlerCambioNumeroExtNueva,
        HandlerCambioNumIntNueva,
        HandlerCambioCpNueva,
        HandlerCambioColoniaNueva,
        HandlerCambioCiudadNueva,
        HandlerCambioMunicipioNueva,
        HandlerCambioEstadoNueva,
        HandlerCambioCorreoNueva,
        HandlerCambioTelefonoNueva,
        LatitudeNueva,
        LongitudeNueva,
        HanlderCambioLatitudeNueva,
        HanlderCambioLongitudeNueva,
        LimpiarNueva,

        // PETICION
        Codigopostal,
        TelefonoPeticion,
        CallePeticion,
        NumeroPeticion,
        ColoniaPeticion,
        CiudadPeticion,
        EstadoPeiticion,
        PaisPeticion,
        ReferenciaPeticion,
        LimpiarNuevapeticion,
        HandlerCambioCodigoposta,
        HanlderCambioTelefonoPeticion,
        HanlderCambioCallePeticion,
        HanlderCambioNumeroPeticion,
        HanlderCambioColoniaPeticion,
        HandlerCambioCiudadPeticion,
        HanlderCambioEstadoPeticion,
        HandlerCambioPaisPeticion,
        HanlderCambioReferenciaPeticion,
        HanlderSelecionarRecolector,
        Recolector,
        // DETALLES
        Cantidad,
        Contenido,
        Largo,
        Ancho,
        Alto,
        Peso,
        HandlerCantidad,
        HanlderContenido,
        HanlderLargo,
        HandlerAncho,
        HanlderAlto,
        HanlderPeso,
        TipoDetalles,
        HanlderTipodetalle,
        // MODAL DETALLES 
        TipoModalDetalle,
        HanlderTipoDetalle,
        //NUEVO CLIENTE
        handleNewClteInfo,
        AñadirNuevoCliente,
        handlerNombreCliente,
        nombreCliente,
        VistaNuevoCliente,
        AbrirVistaNuevoCliente,
        CerrarVistaNuevoCliente,
    } = useNuevaPeticion()

    const { CargarClientes, ClientesPaginador, CargarClientesPaginador } = useClientes()
    const {
        ModalClientePeticion,
        AbrirModalClientePeticiones,
        CerrarModalClientePeticiones,
        ModalDireccion,
        AbrirModalDirecciones,
        CerrarModalDirecciones,
        ModalSinDireccion,
        AbrirModalSinDirecciones,
        CerrarModalSinDirecciones,
        ModalOrigenPeticiones,
        AbrirModalOrigenPeticiones,
        CerrarModalOrigenPeticiones,
        ModalDetallesOnsitePeticiones,
        AbrirModalDatosOnsitePeticiones,
        CerrarModalDatosOnsitePeticiones,
        ModalNuevaDrireccionRecolecion,
        AbrirNuevaDireccionRecolecion,
        CerrarNuevaDireccionRecoleccion,
        ModalResumenPeTicion,
        AbrirModalResumenPeticiones,
        CerrarModalResumenPeticiones,
        AbrirModalMercanciaPeticion,
        CerrarModalMercanciaPeticion,
        ModalMercanciaPeticion,
        //BUTTON
        disable,
        setdisable
    } = useModal()
    const {
        CargarDireccionesDeRecoleccion,
        DireccionesRecoleccion,
        CargarDirecciones,
        PrimeraDireccion,
        ActuliazarDireccionARecolecion,
        GeocoderData,
        LimpiarGeocoder,
        GeocoderObtenerDireccion,
        AñadirNuevaDireccion,
        SeleccionarDireccion,
        DireccionSeleccionada,
        ActuliazarDireccionARecolecionPETICION,
        LoadingDireccionPeticion,
        LoadingNuevaDireccion,
        ActualiazarDireccionARecolecionNuevaPeticion
    } = useDirecciones()
    const { HandlerGetDatosOnsite, DatosOnsite, HanlderObtenerDetallesOnsite, DatosDetalles, HanlderUpdateDatosDetalles } = useDatosOnsite()
    const { CargarCodigoPostales, Datacp, LoadingCp } = useAutocompletecp()
    const { NuevaPeticion, LoadingAñadirnueva } = UsePeticiones()
    const [TotalBultosOnsite, setTotalBultosOnsite] = useState(0);
    const [TotalGuiasOnsite, setTotalGuiasOnsite] = useState(0);
    const [BanderaNuevapeticionVerificar, setBanderaNuevapeticionVerificar] = useState(false)
    const [EditarDireccionnuevapeticion, setEditarDireccionnuevapeticion] = useState(false)
    const [ResumenPeticion, setResumenPeticion] = useState();
    const [ShowMercancia, setShowMercancia] = useState(false);
    //MERCANCIA
    const [NuevaMercancia, setNuevaMercancia] = useState(0);
    //NUEVO CLIENTE
    const [datosNuevoCliente, setdatosNuevoCliente] = useState({
        tipo: '',
        nombre: '',
        rfc: '',
        calle: '',
        noint: '',
        noext: '',
        cp: '',
        telefono: '',
        ciudad: '',
        municipio: '',
        colonia: '',
        tipodomicilio: '',
        credito: '',
        activo: '',
        observacion: '',
        idfranquicia: '',
        pais: '',
        estado: '',
        montocredito: '',
        sexo: '',
        edad: '',
        giro: '',
        idclienteOs: '',
        impauto: '',
        estatuscredito: '',
        tipoDireccion: '',
        idCiudad: '',
        coordenadas: {
          latitude: '',
          longitude: ''
        }
    })
    //TIPO DE PETICION
    const [TipoPeticionSeleccionado, setTipoPeticionSeleccionado] = useState()
    const HanlderTipoPeticion = (e, tipo) => {
        e.preventDefault()
        if (tipo === "RECOLECCION") {
            setTipoPeticionSeleccionado("Recoleccion")
        } else if (tipo === "ENTREGA") {
            setTipoPeticionSeleccionado("Entrega")
        } else if (tipo === "COBRANZA") {
            setTipoPeticionSeleccionado("Cobranza")
        } else if (tipo === "BANCO") {
            setTipoPeticionSeleccionado("Banco")
        } else if (tipo === "OTRO") {
            setTipoPeticionSeleccionado("Otro")
        }
    }

    //NUEVO CLIENTE
    const NuevoCliente = () => {
        CerrarModalClientePeticiones()
        AbrirVistaNuevoCliente()
    }

    const handleChangeDireccion = (datos) => {
        HanlderCambioCallePeticion(datos.calle);
        HanlderCambioNumeroPeticion(datos.noext);
        HanlderCambioColoniaPeticion(datos.colonia);
        HandlerCambioCiudadPeticion(datos.ciudad);
      };
      
    useEffect(() => {
        if (datosNuevoCliente) {
            SeleccionarDireccion(datosNuevoCliente)
            HanlderClienteBuscador(datosNuevoCliente.nombre)
            HanlderCambioTelefonoPeticion(datosNuevoCliente.telefono)
            handleChangeDireccion(datosNuevoCliente)
            HandlerCambioCodigoposta(datosNuevoCliente.cp)
            HanlderCambioEstadoPeticion(datosNuevoCliente.estado)
            HandlerCambioPaisPeticion(datosNuevoCliente.pais)
            // SeleccionarclienteNuevo(datosNuevoCliente)
        }
      }, [datosNuevoCliente]);

    const handlerNuevoCliente = async () => {
        let requestNuevoCte = {
            "tipo": "Moral",
            "nombre": nombreCliente,
            "rfc": 'XAXX010101000',
            "calle": CalleNueva,
            "noint": NumIntNueva === undefined ? 's/n' : NumIntNueva,
            "noext": NumeroExtNueva,
            "cp": CpNueva,
            "telefono": TelefonoNueva,
            "ciudad": CiudadNueva,
            "municipio": MunicipioNueva,
            "colonia": ColoniaNueva,
            "tipodomicilio": "Fiscal",
            "credito": "No",
            "cheque": "",
            "activo": "Si",
            "observacion": "",
            "idfranquicia": user?.idsucursal,
            "pais": "MX",
            "estado": EstadoNueva,
            "montocredito": 0,
            "diapago": null,
            "diarevision": null,
            "tipocontrarecibo": "",
            "factquincenal": "No",
            "sexo": "H",
            "edad": 27,
            "giro": 0,
            "idclienteOs": null,
            "impauto": "Si",
            "estatuscredito": 0,
            // "regFiscal": 616
            "tipoDireccion": 2,
            "idCiudad":  JSON.parse(localStorage.getItem("Ciudad")).idciudad,
            "coordenadas": {
                "latitude": LatitudeNueva.toString(),
                "longitude": LongitudeNueva.toString()
            },
        }
          // Función para guardar el cliente
        await AñadirNuevoCliente(requestNuevoCte, user?.id)
         // Guarda el requestNuevoCte en el estado
        setdatosNuevoCliente(requestNuevoCte)
         // Otras acciones
        LimpiarGeocoder()
        LimpiarNueva()
        // NuevoCliente()
    }
    const HanlderMostrarDatosNvoCte = async (res) => {
        SeleccionarDireccion(res)
        // HandlerCambioCodigoposta(res.Cp)
        // HanlderCambioTelefonoPeticion(res.Telefono)
        // HanlderCambioCallePeticion(prueba.Calle)
        // HanlderCambioNumeroPeticion(prueba.Noext)
        // HandlerCambioNumIntNueva(prueba.Noint)
        // HanlderCambioColoniaPeticion(prueba.Colonia)
        // HandlerCambioCiudadPeticion(prueba.Ciudad)
        // HanlderCambioEstadoPeticion(prueba.Estado)
        // HandlerCambioPaisPeticion(prueba.Pais)
    }
   
    //PAGINADOR
    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", background: "transparent", height: '85px' }}
                onClick={onClick}
            >
                <img src={prev} alt="" />
            </div>
        );
    }
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", background: "transparent", height: '85px' }}
                onClick={onClick}
            >
                <img src={next} alt="" />
            </div>
        );
    }
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />

    };

    const HanlderShowMercancioa = () => {
        if (ShowMercancia === true) {
            setShowMercancia(false)
        } else {
            setShowMercancia(true)
        }
    }
    const HanlderEditarNuevaDireccion = (data) => {
        setEditarDireccionnuevapeticion(data)
    }
    // FUNCION PARA SELECCIONAR CLIENTE
    const Seleccionarcliente = async (data) => {

        await HanlderClienteBuscador(data.nombrecompleto)
        await CargarDireccionesDeRecoleccion(data.id, JSON.parse(localStorage.getItem("Ciudad")).idciudad)
        await CargarDirecciones(data.id)
        HanlderSeleccionarCliente(data)
        // setdireccionPeticion(true)
    }
    // const SeleccionarclienteNuevo = async (data) => {
    //     HanlderSeleccionarCliente(data)
    //     // setdireccionPeticion(true)
    // }
    useEffect(() => {
        if (ClienteSeleccionado) {
            // LOGICA PARA ONSITE
            CerrarModalClientePeticiones()
            if (OrigenSelecionado === "OnSite") {
                if (DireccionesRecoleccion === [] || DireccionesRecoleccion?.length === 0 && ClienteSeleccionado !== undefined) {
                    CerrarModalDirecciones()
                    AbrirModalSinDirecciones()
                } else {
                    HandlerGetDatosOnsite(JSON.parse(localStorage.getItem("Ciudad")).idciudad, ClienteSeleccionado.id)

                }
            } else if (OrigenSelecionado === "Sucursal") {
                if (DireccionesRecoleccion === [] || DireccionesRecoleccion?.length === 0 && ClienteSeleccionado !== undefined) {
                    CerrarModalDirecciones()
                    AbrirModalSinDirecciones()
                } else {
                    AbrirModalDirecciones()
                }
            } else {
                AbrirModalOrigenPeticiones()
            }
        }
    }, [DireccionesRecoleccion, ClienteSeleccionado])

    useEffect(() => {
        if (DatosOnsite && ClienteSeleccionado) {

            let Datos = DatosOnsite.map(r => r.id)
            HanlderObtenerDetallesOnsite(JSON.parse(localStorage.getItem("Ciudad")).idciudad, ClienteSeleccionado.id, Datos)
            AbrirModalDatosOnsitePeticiones()
        } else if (!DatosOnsite && ClienteSeleccionado) {

            if (DireccionesRecoleccion === [] || DireccionesRecoleccion?.length === 0 && ClienteSeleccionado !== undefined) {
                CerrarModalDirecciones()
                AbrirModalSinDirecciones()
            } else {
                AbrirModalDirecciones()
            }
        }

    }, [DatosOnsite])

    useEffect(() => {
        if (ClientesPaginador === undefined) {
            CargarClientes()
        }
    }, [ClientesPaginador, CargarClientes])
    // AREA DE FUNCIONES

    const funcionprueba = (id) => {
        alert(id, "PRUEBA")
    }
    // console.log(ClienteSeleccionado, "select cliente")
    // FUNCION PARA ACTUALIZAR DIRECCION DE RECOLECION
    const AñadirDirecionRecolecionP = async (direccion) => {
        // await ActuliazarDireccionARecolecion(direccion, user?.id, 3)
        await ActualiazarDireccionARecolecionNuevaPeticion(direccion, user?.id)
        await CargarDireccionesDeRecoleccion(ClienteSeleccionado.id, JSON.parse(localStorage.getItem("Ciudad")).idciudad)
        CerrarModalSinDirecciones()
        AbrirModalDirecciones()
    }

    const NuevaDireccion = async () => {
        await CargarDireccionesDeRecoleccion(ClienteSeleccionado.id, JSON.parse(localStorage.getItem("Ciudad")).idciudad)
        CerrarModalSinDirecciones()
        AbrirModalDirecciones()
    }
    // FUNCION PARA AÑADIR NUEVA DIRECCION DE RECOLECION 
    const AñadirNuevaDirecciondeRecolecion = () => {
        AbrirNuevaDireccionRecolecion()
        CerrarModalDirecciones()
        CerrarModalSinDirecciones()
    }
    // FUNCION PARA SELECCIONAR ORITEN EN MODAL
    const SeleccionarorigenModal = async (e, origen) => {
        if (origen === "ONSITE") {
            await HanlderCambioOrigen(e, "ONSITE", undefined)
            if (DireccionesRecoleccion === [] || DireccionesRecoleccion?.length === 0 && ClienteSeleccionado !== undefined) {

                CerrarModalOrigenPeticiones()
                CerrarModalDirecciones()
                AbrirModalSinDirecciones()
            } else {
                // alert("SS")
                CerrarModalOrigenPeticiones()
                CerrarModalDirecciones()
                if (DireccionesRecoleccion === [] || DireccionesRecoleccion?.length === 0 && ClienteSeleccionado !== undefined) {
                    CerrarModalDirecciones()
                    AbrirModalSinDirecciones()
                } else {
                    HandlerGetDatosOnsite(JSON.parse(localStorage.getItem("Ciudad")).idciudad, ClienteSeleccionado.id)

                }
            }
            // CerrarModalOrigenPeticiones()
        } else {
            await HanlderCambioOrigen(e, "SUCURSAL", undefined)
            if (DireccionesRecoleccion === [] || DireccionesRecoleccion?.length === 0 && ClienteSeleccionado !== undefined) {

                CerrarModalOrigenPeticiones()
                CerrarModalDirecciones()
                AbrirModalSinDirecciones()
            } else {
                CerrarModalOrigenPeticiones()
                AbrirModalDirecciones()
            }

        }
    }
    // FUNCION PARA LLENAR ARREGLO DE ONSITE
    const AbrirModalDirconDatosOnsite = (e) => {
        e.preventDefault()
        if (DireccionesRecoleccion === [] || DireccionesRecoleccion?.length === 0 && ClienteSeleccionado !== undefined) {

            CerrarModalDatosOnsitePeticiones()
            CerrarModalDirecciones()
            AbrirModalSinDirecciones()
        } else {
            CerrarModalDatosOnsitePeticiones()
            AbrirModalDirecciones()
        }
    }
    // FUNCION PARA LLENAR CP 
    const HandlerPrecargaCodigopostalNueva = async (data) => {
        HandlerCambioCpNueva(data.cp)
        HandlerCambioColoniaNueva(data.colonia)
        HandlerCambioCiudadNueva(data.ciudad)
        HandlerCambioEstadoNueva(data.estado)
        HandlerCambioPaisNueva(data.pais)
        HandlerCambioMunicipioNueva(data.municipio)
    }
    
    const RenderItemCabezera = () => {
        return (
            <>
                <div className='ItemHeader-TablaSinPaginador' style={{ flex: 1 }}>
                    <p>No.Cliente</p>
                </div>
                <div className='ItemHeader-TablaSinPaginador' style={{ flex: 2 }}>
                    <p>Nombre</p>
                </div>
                <div className='ItemHeader-TablaSinPaginador' style={{ flex: 1 }}>
                    <p>RFC</p>
                </div>
                <div className='ItemHeader-TablaSinPaginador' style={{ flex: 3 }}>
                    <p>Dirección</p>
                </div>
            </>
        )
    }
    const RenderItemCuerpo = () => {
        return (
            <>
                <div className="cuerpoTabla">
                    {
                        ClientesPaginador?.map(res => {
                            return (
                                <>
                                    <div className='Cuerpo-TablaSinPaginador' onClick={() => Seleccionarcliente(res)}>
                                        <div className='ItemCuerpo-TablaSinpaginador' style={{ flex: 1 }}>
                                            <p>{res.id}</p>
                                        </div>
                                        <div className='ItemCuerpo-TablaSinpaginador' style={{ flex: 2 }}>
                                            <p>{res.nombrecompleto}</p>
                                        </div>
                                        <div className='ItemCuerpo-TablaSinpaginador' style={{ flex: 1 }}>
                                            <p>{res.rfc}</p>
                                        </div>
                                        <div className='ItemCuerpo-TablaSinpaginador' style={{ flex: 3 }}>
                                            <p>{res.calle} {res.noext}, {res.colonia}, {res.cp}, {res.ciudad}, {res.estado}, {res.pais}</p>
                                        </div>
                                    </div>
                                </>
                            )
                        })
                    }
                </div>
            </>
        )
    }

    const RenderItem = () => {
        return (
            <div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <div onClick={() => NuevoCliente()} className='iconnuevo-peticion' style={{ marginTop: '10px', cursor: 'pointer' }}>
                        <NuevoIcon width={40} height={40}></NuevoIcon>
                    </div>
                </div>
                <div style={{ marginTop: '-30px' }}>
                    <TablaSinPaginador
                        Cabezera={RenderItemCabezera}
                        Cuerpo={RenderItemCuerpo}
                        Modal={true}
                    ></TablaSinPaginador>
                </div>
            </div>
        )
    }
    // DIRECCIONES
    const RenderItemCabezeraDirecciones = () => {
        return (
            <>
                <div className='ItemHeader-TablaSinPaginador' style={{ flex: 3 }}>
                    <p>Calle</p>
                </div>
                <div className='ItemHeader-TablaSinPaginador' style={{ flex: 2 }}>
                    <p>Núm Ext.</p>
                </div>
                <div className='ItemHeader-TablaSinPaginador' style={{ flex: 1 }}>
                    <p>Colonia</p>
                </div>
                <div className='ItemHeader-TablaSinPaginador' style={{ flex: 3 }}>
                    <p>Ciudad</p>
                </div>
                <div className='ItemHeader-TablaSinPaginador' style={{ flex: 1 }}>
                    <p>Estado</p>
                </div>
            </>
        )
    }
    // const HandlerDireccionPeti = (res) => {
    //     setCallePet(res.calle)
    //     setTelefonoPet(res.telefono)
    //     setcoloniaPet(res.colonia)

    // }
    // FUNCION PARA SELECCIONAR DIRECCION
    const HanlderSelectDireccion = async (res) => {
        SeleccionarDireccion(res)
        HandlerCambioCodigoposta(res.cp)
        HanlderCambioTelefonoPeticion(res.telefono)
        HanlderCambioCallePeticion(res.calle)
        HanlderCambioNumeroPeticion(res.noext)
        HandlerCambioNumIntNueva(res.noint)
        HanlderCambioColoniaPeticion(res.colonia)
        HandlerCambioCiudadPeticion(res.localidad)
        HanlderCambioEstadoPeticion(res.estado)
        HandlerCambioPaisPeticion(res.pais)
        CerrarModalDirecciones()
    }
    // console.log(CallePeticion, "calle p")
    const RenderItemCuerpoDirecciones = () => {
        return (
            <>
                <div className="cuerpoTabla">
                    {
                        DireccionesRecoleccion?.map(res => {
                            return (
                                <>
                                    <div className='Cuerpo-TablaSinPaginador' onClick={() => HanlderSelectDireccion(res)}>
                                        <div className='ItemCuerpo-TablaSinpaginador' style={{ flex: 3 }}>
                                            <p>{res.calle}</p>
                                        </div>
                                        <div className='ItemCuerpo-TablaSinpaginador' style={{ flex: 2 }}>
                                            <p>{res.noext}</p>
                                        </div>
                                        <div className='ItemCuerpo-TablaSinpaginador' style={{ flex: 1 }}>
                                            <p>{res.colonia}</p>
                                        </div>
                                        <div className='ItemCuerpo-TablaSinpaginador' style={{ flex: 3 }}>
                                            <p>{res.localidad}</p>
                                        </div>
                                        <div className='ItemCuerpo-TablaSinpaginador' style={{ flex: 1 }}>
                                            <p>{res.estado}</p>
                                        </div>
                                    </div>

                                </>
                            )
                        })
                    }
                </div>
            </>
        )
    }
    // CUERPO DE MODAL DIRECCIONES
    const RenderItemDireccion = () => {
        return (
            <div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10, cursor: 'pointer' }}>
                    <div onClick={() => AñadirNuevaDirecciondeRecolecion()} className='iconnuevo-peticion'>
                        <NuevoIcon width={40} height={40}></NuevoIcon>
                    </div>
                </div>
                <TablaSinPaginador
                    Cabezera={RenderItemCabezeraDirecciones}
                    Cuerpo={RenderItemCuerpoDirecciones}
                    Modal={true}
                ></TablaSinPaginador>
            </div>
        )
    }
    const RenderItemSinDireccion = () => {

        return (
            LoadingDireccionPeticion ?
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '30%', margin: 'auto' }}>
                        <Loader
                            title="cargando datos"
                        ></Loader>
                    </div>
                </>
                :
                <div>
                    <div className='ContainerCheckAddress'>
                        <p>El cliente {ClienteSeleccionado?.nombrecompleto} no cuenta con dirección configurada para recolección desea añadir la dirección:</p>
                        <p>{`
                ${PrimeraDireccion?.calle} 
                ${PrimeraDireccion?.noint} 
                ${PrimeraDireccion?.colonia} 
                ${PrimeraDireccion?.cp} 
                ${PrimeraDireccion?.localidad} 
                ${PrimeraDireccion?.estado} 
                ${PrimeraDireccion?.pais}
                ` }</p>
                    </div>
                    <div className='ContainerCheckAddressButton'>
                        <div className='Boton_Aceptar'>
                            <button onClick={() => AñadirDirecionRecolecionP(PrimeraDireccion)}>Aceptar</button>
                        </div>

                        <div className='Boton_NuevaPeticion'>
                            <button onClick={() => AñadirNuevaDirecciondeRecolecion()} style={{ cursor: 'pointer' }} >Agregar Nueva +</button>
                        </div>
                    </div>

                </div>
        )
    }

    // CABEZERA MERCANCIA ONSITE
    const RenderitemCabezeraMercanciaOnsite = () => {
        return (
            <>
                <div className='ItemHeader-TablaSinPaginador' style={{ flex: 1 }}>
                    <p>Alianza</p>
                </div>
                <div className='ItemHeader-TablaSinPaginador' style={{ flex: 2 }}>
                    <p>Tipo entrega</p>
                </div>
                <div className='ItemHeader-TablaSinPaginador' style={{ flex: 1 }}>
                    <p>No.rastreo</p>
                </div>
                <div className='ItemHeader-TablaSinPaginador' style={{ flex: 2 }}>
                    <p>Tipo mercancía</p>
                </div>
                <div className='ItemHeader-TablaSinPaginador' style={{ flex: 1 }}>
                    <p>Cantidad</p>
                </div>
                <div className='ItemHeader-TablaSinPaginador' style={{ flex: 1 }}>
                    <p>Contenido</p>
                </div>
                <div className='ItemHeader-TablaSinPaginador' style={{ flex: 3 }}>
                    <p>Dimensiones</p>
                </div>
                <div className='ItemHeader-TablaSinPaginador' style={{ flex: 1 }}>
                    <p>Seleccionar</p>
                </div>
            </>
        )
    }

    // INFO MERCANCIA
    const RenderitemINFOCabezeraMercanciaOnsite = () => {
        return (
            <>
                {/* <div className='ItemHeader-TablaSinPaginador' style={{ flex: 1 }}>
                    <p>Alianza</p>
                </div> */}

                <div className='ItemHeader-TablaSinPaginador' style={TipoModalDetalle === 'Onsite' ? { flex: 3 } : { display: 'none' }}>
                    <p>No.rastreo</p>
                </div>
                <div className='ItemHeader-TablaSinPaginador' style={{ flex: 1 }}>
                    <p>Tipo</p>
                </div>
                <div className='ItemHeader-TablaSinPaginador' style={{ flex: 2 }}>
                    <p>Cantidad</p>
                </div>
                <div className='ItemHeader-TablaSinPaginador' style={{ flex: 4 }}>
                    <p>Contenido</p>
                </div>
                <div className='ItemHeader-TablaSinPaginador' style={{ flex: 4 }}>
                    <p>Dimensiones</p>
                </div>
                <div className='ItemHeader-TablaSinPaginador' style={{ flex: 1 }}>
                    {/* <p>Acción</p> */}
                </div>
            </>
        )
    }

    const HandlerMercanciaOnsite = (data) => {
        HandlerSelectMercanciaOS(data)
        HanlderTipoDetalle("Onsite")
    }
    const RenderitemMercanciaOnsite = () => {

        return (
            <>
                <div className="ContenedorTablaOnsite" style={{ maxHeight: '50vh', overflowY: 'auto', overflowX: 'hidden' }}>
                    {
                        DatosDetalles?.map(res => {

                            return (
                                <>
                                    <div className='Cuerpo-TablaSinPaginador'>
                                        <div className='ItemCuerpo-TablaSinpaginador' style={{ flex: 1, height: '77px', flexDirection: 'column' }}>
                                            <div style={{ height: '100%' }}>
                                                <img style={{ height: '50px', width: '70px' }} src={res.nombrealianza === "PAQUETEXPRESS" ? imgPX : res.nombrealianza === "FEDEX" ? imgFEDEX : res.nombrealianza === "DHL" ? imgDHL : imgUPS} />
                                                <p style={{ fontSize: '12px', marginTop: '-10px' }}>{res.nombreproductoalianza}</p>
                                            </div>
                                        </div>
                                        <div className='ItemCuerpo-TablaSinpaginador' style={{ flex: 2, height: '77px' }}>
                                            <p>{res.tipoEntrega}</p>
                                        </div>
                                        <div className='ItemCuerpo-TablaSinpaginador' style={{ flex: 1, height: '77px' }}>
                                            <p>{res.norastreo.substring(4)}</p>
                                        </div>
                                        <div className='ItemCuerpo-TablaSinpaginador' style={{ flex: 2, height: '77px' }}>
                                            <img style={{ height: '50px', width: '50px' }} src={res.tipo == 0 ? imgSobre : res.tipo == 1 ? imgCaja : imgTarima} />
                                        </div>
                                        <div className='ItemCuerpo-TablaSinpaginador' style={{ flex: 1, height: '77px' }}>
                                            <p>{res.cantidad}</p>
                                        </div>
                                        <div className='ItemCuerpo-TablaSinpaginador' style={{ flex: 1, height: '77px' }}>
                                            <p>{res.contenido}</p>
                                        </div>
                                        <div className='ItemCuerpo-TablaSinpaginador' style={{ flex: 3, height: '77px' }}>
                                            <p>{`${parseInt(res.largo).toFixed()}cm x ${parseInt(res.ancho).toFixed()}cm x ${parseInt(res.alto).toFixed()}cm ${parseInt(res.peso).toFixed(2)}Kg`}</p>
                                        </div>
                                        <div onClick={() => HandlerMercanciaOnsite(res.id)} className='ItemCuerpo-TablaSinpaginador' style={{ flex: 1 }}>
                                            <img style={{ height: '25px', width: '25px', cursor: 'pointer' }} src={SelectMercanciaOnsite.includes(res.id) ? imgCheckbox : imgCheckboxoff} />
                                        </div>
                                    </div>
                                </>
                            )
                        })
                    }
                </div>
                <div style={{ paddingTop: '7px' }} className="btnAceptar">
                    <button onClick={(e) => AbrirModalDirconDatosOnsite(e)}>Aceptar</button>
                </div>
            </>
        )
    }

    // INFO MERCANCIA ONSITE
    const RenderitemINFOMercanciaOnsite = () => {

        return (
            <>
                <div style={{ overflow: 'auto', maxHeight: '28vh' }} className="ContenedorTablaMercancia">
                    {
                        DatosDetalles?.map(res => {

                            if (SelectMercanciaOnsite.includes(res.id))
                                return (
                                    <>
                                        <div className='Cuerpo-TablaSinPaginador'>
                                            {/* <div className='ItemCuerpo-TablaSinpaginador' style={{ flex: 1 }}>
                                        <img style={{ height: '50px', width: '70px' }} src={res.nombrealianza === "PAQUETEXPRESS" ? imgPX : res.nombrealianza === "FEDEX" ? imgFEDEX : res.nombrealianza === "DHL" ? imgDHL : imgUPS} />
                                        <p style={{ fontSize: '12px', marginTop: '-10px' }}>{res.nombrealianza}</p>
                                    </div> */}
                                            <div className='ItemCuerpo-TablaSinpaginador' style={TipoModalDetalle === 'Onsite' ? { flex: 3 } : { display: 'none' }}>
                                                <img style={{ height: '50px', width: '70px' }} src={res.nombrealianza === "PAQUETEXPRESS" ? imgPX : res.nombrealianza === "FEDEX" ? imgFEDEX : res.nombrealianza === "DHL" ? imgDHL : imgUPS} />
                                                <p>{res.norastreo.substring(4)}</p>
                                            </div>
                                            <div className='ItemCuerpo-TablaSinpaginador' style={{ flex: 1, display: 'flex', alignContent: 'center' }}>
                                                <img style={{ height: '40px', width: '40px' }} src={res.tipo == 0 ? imgSobre : res.tipo == 1 ? imgCaja : imgTarima} />
                                            </div>
                                            <div className='ItemCuerpo-TablaSinpaginador' style={{ flex: 2 }}>
                                                <p>{res.cantidad}</p>
                                            </div>
                                            <div className='ItemCuerpo-TablaSinpaginador' style={{ flex: 4 }}>
                                                <p>{res.contenido}</p>
                                            </div>
                                            <div className='ItemCuerpo-TablaSinpaginador' style={{ flex: 4 }}>
                                                <p>{`${parseInt(res.largo).toFixed()}cm x ${parseInt(res.ancho).toFixed()}cm x ${parseInt(res.alto).toFixed()}cm ${parseInt(res.peso).toFixed(2)}Kg`}</p>
                                            </div>
                                            <div className='ItemCuerpo-TablaSinpaginador' style={{ flex: 1, display: 'flex', alignContent: 'center' }}>
                                                <img style={{ width: '24px' }} src={EliminarIcon} alt="" onClick={() => EliminarMercancia(res.id)} />
                                            </div>
                                        </div>
                                    </>
                                )
                        })
                    }
                </div>
            </>
        )
    }
    const RenderItemDatosOnsite = () => {
        let datos = 0;
        DatosDetalles?.map(res => {
            datos = datos + Number(res.cantidad)

            return setTotalBultosOnsite(datos)
        })
        return (
            <div>
                <div className='HeaderPaginatorNew'>
                    <div className='ContainerActionsHeaderTable' style={{ display: 'flex', width: '100%', justifyContent: 'space-between', flexDirection: 'row', marginTop: '20px' }}>
                        <div className="generalInfoShow">
                            <p>Cliente</p>
                            <p>{ClienteSeleccionado?.nombrecompleto}</p>
                        </div>
                        <div className="generalInfoShow">
                            <p>Total de bultos</p>
                            <p style={{ paddingLeft: '8px' }}>{TotalBultosOnsite}</p>
                        </div>
                        <div className="generalInfoShow">
                            <p>Total de guias</p>

                            <p style={{ paddingLeft: '8px' }}>{DatosDetalles?.length}</p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }} className="btnSeleccionartodo">
                            <button onClick={DatosDetalles?.length === SelectMercanciaOnsite?.length ? () => HandlerDeseleccionar() : () => HandlerSelectMercanciaOSAll(DatosDetalles)}>{DatosDetalles?.length === SelectMercanciaOnsite?.length ? 'Desmarcar Todas' : 'Seleccionar Todas'}</button>
                        </div>
                    </div>
                </div>
                <TablaSinPaginador
                    Cabezera={RenderitemCabezeraMercanciaOnsite}
                    Cuerpo={RenderitemMercanciaOnsite}
                    Modal={true}
                >

                </TablaSinPaginador>
            </div>
        )
    }

    // FUNCION PARA Verificar Ubicacion EN nueva peticion
    const HanlderVerificarNueva = async () => {
        setBanderaNuevapeticionVerificar(true)
        let DireccionCadena = `${CalleNueva} ${NumIntNueva === "s/n" ? NumeroExtNueva : NumeroExtNueva},${ColoniaNueva},${CpNueva},${CiudadNueva},${EstadoNueva}.,${PaisNueva}`

        await GeocoderObtenerDireccion(DireccionCadena)

    }
    useEffect(() => {
        if (GeocoderData && BanderaNuevapeticionVerificar) {
            HanlderCambioLatitudeNueva(GeocoderData.lat)
            HanlderCambioLongitudeNueva(GeocoderData?.lng)
        } else {
            LimpiarGeocoder()
        }
    }, [GeocoderData, BanderaNuevapeticionVerificar])
    // FUNCION PARA AÑADIR NUEVA DIRECCION
    const HanlderNuevaDireccion = async () => {
        let requestNuevaDireccion = {
            "calle": CalleNueva,
            "noext": NumeroExtNueva,
            "noint": NumIntNueva === undefined ? '' : NumIntNueva,
            "colonia": ColoniaNueva,
            "cp": CpNueva,
            "localidad": CiudadNueva,
            "estado": EstadoNueva,
            "idcliente": ClienteSeleccionado.id,
            "pais": PaisNueva,
            "correo": CorreoNueva,
            "telefono": TelefonoNueva,
            "original": "No",
            "municipio": MunicipioNueva,
            "idasesor": user?.id,
            "coordenadas": {
                "latitude": LatitudeNueva.toString(),
                "longitude": LongitudeNueva.toString()
            },
            "tipo": 2
        }
        await AñadirNuevaDireccion(requestNuevaDireccion, user?.id)
        CerrarNuevaDireccionRecoleccion()
        LimpiarGeocoder()
        LimpiarNueva()
        NuevaDireccion()
        HanlderEditarNuevaDireccion(false)

    }


    const RenderItemNuevaDireccion = () => {
        return (
            LoadingNuevaDireccion ?
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '30%', margin: 'auto' }}>
                    <Loader
                        title="Añadiendo Direccion"
                    ></Loader>
                </div>
                :
                <>
                    <div>
                        <div className="generalInfoShow" style={{ width: '48%' }}>
                            <p>Cliente: {ClienteSeleccionado?.nombrecompleto} </p>
                            <p style={{ paddingLeft: '8px' }}></p>
                        </div>
                    </div>
                    <div className='Contenido-Grid-NuevadireccionRecoleccion'>
                        <div className='MapaNueva'>
                            <MapaclientePeticion
                                Direccion={GeocoderData}
                                AccionCambioLat={HanlderCambioLatitudeNueva}
                                AccionCambioLng={HanlderCambioLongitudeNueva}
                                AccionEditar={HanlderEditarNuevaDireccion}
                            ></MapaclientePeticion>
                        </div>
                        <div className='Nuevadireccion'>
                            <div className='Contenedor1-direccionRecolecion'>
                                <PeticionesLayout>
                                    <div className="inputsContenedor">
                                        <div>
                                            <div className='ContainerInfoClienteInputs'>
                                                <InputComponent
                                                    nombre={"Buscar por código postal"}
                                                    tipo={"CodigoPostal"}
                                                    flex={1}
                                                    datos={Datacp}
                                                    BuscarCp={CargarCodigoPostales}
                                                    AccionCargaCp={HandlerPrecargaCodigopostalNueva}
                                                    Loading={LoadingCp}

                                                ></InputComponent>
                                                <InputComponent
                                                    nombre={"País"}
                                                    tipo={"Text"}
                                                    flex={1}
                                                    Accion={HandlerCambioPaisNueva}
                                                    value={PaisNueva}

                                                ></InputComponent>
                                            </div>

                                            <div className='ContainerInfoClienteInputs'>
                                                <InputComponent
                                                    nombre={"Calle"}
                                                    tipo={"Text"}
                                                    flex={1}
                                                    Accion={HandlerCambioCalleNueva}
                                                    value={CalleNueva}

                                                ></InputComponent>
                                                <InputComponent
                                                    nombre={"Núm. Ext."}
                                                    tipo={"Text"}
                                                    flex={1}
                                                    Accion={HandlerCambioNumeroExtNueva}
                                                    value={NumeroExtNueva}

                                                ></InputComponent>
                                                <InputComponent
                                                    nombre={"Núm. Int."}
                                                    tipo={"Text"}
                                                    flex={1}
                                                    Accion={HandlerCambioNumIntNueva}
                                                    value={NumIntNueva}
                                                ></InputComponent>
                                            </div>

                                            <div className='ContainerInfoClienteInputs'>
                                                <InputComponent
                                                    nombre={"C. P."}
                                                    tipo={"Text"}
                                                    flex={1}
                                                    Accion={HandlerCambioCpNueva}
                                                    value={CpNueva}
                                                ></InputComponent>
                                                <InputComponent
                                                    nombre={"Colonia"}
                                                    tipo={"Text"}
                                                    flex={1}
                                                    Accion={HandlerCambioColoniaNueva}
                                                    value={ColoniaNueva}
                                                ></InputComponent>
                                            </div>

                                            <div className='ContainerInfoClienteInputs'>
                                                <InputComponent
                                                    nombre={"Ciudad"}
                                                    tipo={"Text"}
                                                    flex={1}
                                                    Accion={HandlerCambioCiudadNueva}
                                                    value={CiudadNueva}
                                                ></InputComponent>
                                                <InputComponent
                                                    nombre={"Municipio"}
                                                    tipo={"Text"}
                                                    flex={1}
                                                    Accion={HandlerCambioMunicipioNueva}
                                                    value={MunicipioNueva}
                                                ></InputComponent>
                                                <InputComponent
                                                    nombre={"Estado"}
                                                    tipo={"Text"}
                                                    flex={1}
                                                    Accion={HandlerCambioEstadoNueva}
                                                    value={EstadoNueva}
                                                ></InputComponent>
                                            </div>

                                            <div className='ContainerInfoClienteInputs'>
                                                <InputComponent
                                                    nombre={"Correo"}
                                                    tipo={"Text"}
                                                    flex={1}
                                                    Accion={HandlerCambioCorreoNueva}
                                                    value={CorreoNueva}
                                                ></InputComponent>
                                                <InputComponent
                                                    nombre={"Teléfono"}
                                                    tipo={"Text"}
                                                    flex={1}
                                                    Accion={HandlerCambioTelefonoNueva}
                                                    value={TelefonoNueva}
                                                ></InputComponent>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='ContainerCheckAddressButton'>
                                        <div className='Boton_Aceptar'>
                                            <button style={{ marginTop: '10px' }} onClick={GeocoderData === undefined ? () => HanlderVerificarNueva() : () => HanlderNuevaDireccion()}>{GeocoderData === undefined ? 'Verificar' : 'Guardar'}</button>
                                        </div>
                                    </div>
                                </PeticionesLayout>

                            </div>
                        </div>
                    </div>
                </>
        )
    }

    // CUERPO MODAL ORIGEN
    const RenderItemOrigenPeticion = () => {
        return (
            <div className='container-Origen-MODALPETICIONES'>
                <PeticionesLayout>
                    <div className="imgOrigen">
                        <div className={`containerimgorigen ${OrigenSelecionado === 'Sucursal' ? "active" : ""}`} onClick={(e) => SeleccionarorigenModal(e, "SUCURSAL")}>
                            <img src={imgSucursal} />
                        </div>
                        <div className={`containerimgorigen ${OrigenSelecionado === 'OnSite' ? "active" : ""}`} onClick={(e) => SeleccionarorigenModal(e, "ONSITE")}>
                            <img src={imgOnsite} />
                        </div>
                    </div>
                </PeticionesLayout>
            </div>
        )
    }

    // FUNCION AÑADIR PETICION
    const HanlderSubmitPeticion = async () => {
        setdisable(true)
        let requestPEticion = {
            "origen": OrigenSelecionado,
            "direccion": `${DireccionSeleccionada.calle} ${DireccionSeleccionada.noext}, ${DireccionSeleccionada.colonia}, ${DireccionSeleccionada.cp}, ${DireccionSeleccionada.cp} , ${DireccionSeleccionada.estado}., ${DireccionSeleccionada.pais}`,
            "cliente": ClienteSeleccionado.nombrecompleto,
            "estatus": Recolector[0] == 0 ? "P" : "A",
            "fecha": moment().tz('US/Arizona').format(),
            "usuarioasignado": Recolector[0],
            "usuario": user.id,
            "idciudad": JSON.parse(localStorage.getItem("Ciudad")).idciudad,
            "idcliente": ClienteSeleccionado.id,
            "telefono": TelefonoPeticion,
            "observaciones": ReferenciaPeticion,
            "detalles": [],
            "fechapromesa": moment().tz('US/Arizona').format(),
            "iddireccionrecoleccion": DireccionSeleccionada.id
        }
        if (EditarDireccionnuevapeticion === true) {
            await ActuliazarDireccionARecolecionPETICION(DireccionSeleccionada, user?.id, undefined, LatitudeNueva, LongitudeNueva)
            try {
                await NuevaPeticion(requestPEticion)
                setTimeout(() => {
                    navigate('/Peticiones')
                }, 3000);
            } catch (error) {
                console.error(error)
            }
        } else {
            try {
                await NuevaPeticion(requestPEticion)
                setTimeout(() => {
                    navigate('/Peticiones')
                }, 3000);
            } catch (error) {
                console.error(error)
            }
        }
        let DireccionCadena = `${DireccionSeleccionada.calle} ${DireccionSeleccionada.noint === "s/n" ? DireccionSeleccionada.noext : DireccionSeleccionada.noext},${DireccionSeleccionada.colonia},${DireccionSeleccionada.cp},${DireccionSeleccionada.localidad},${DireccionSeleccionada.estado}.,${DireccionSeleccionada.pais}`
    }
    
    const HanlderSubmitPeticionEDITAR = async () => {

        let requestPEticion = {
            "origen": OrigenSelecionado,
            "direccion": `${DireccionSeleccionada.calle} ${DireccionSeleccionada.noext}, ${DireccionSeleccionada.colonia}, ${DireccionSeleccionada.cp}, ${DireccionSeleccionada.cp} , ${DireccionSeleccionada.estado}., ${DireccionSeleccionada.pais}`,
            "cliente": ClienteSeleccionado.nombre,
            "estatus": "A",
            "fecha": moment().tz('US/Arizona').format(),
            "usuarioasignado": Recolector[0],
            "usuario": user.id,
            "idciudad": JSON.parse(localStorage.getItem("Ciudad")).idciudad,
            "idcliente": ClienteSeleccionado.id,
            "telefono": TelefonoPeticion,
            "observaciones": ReferenciaPeticion,
            "detalles": [],
            "fechapromesa": moment().tz('US/Arizona').format(),
            "iddireccionrecoleccion": DireccionSeleccionada.id
        }
        try {
            await NuevaPeticion(requestPEticion)
            setTimeout(() => {
                navigate('/Peticiones')
            }, 3000);
        } catch (error) {
            console.error(error)
        }
    }
    const RenderItemResumenPeticion = () => {


        // if(DireccionSeleccionada){
        //     HanlderCambioLatitudeNueva(DireccionSeleccionada?.coordenadas.latitude)
        //     HanlderCambioLongitudeNueva(DireccionSeleccionada?.coordenadas.longitude)
        // }
    
        return (
            <>
                <div>
                    <div className="generalInfoShow" style={{ width: '48%' }}>
                        {/* <p>Cliente: {ClienteSeleccionado?.nombrecompleto} {LatitudeNueva}, {LongitudeNueva}</p> */}
                        <p>Cliente: {ClienteSeleccionado === undefined ? ClienteBuscador : ClienteSeleccionado?.nombrecompleto}</p>
                        <p style={{ paddingLeft: '8px' }}></p>
                    </div>
                </div>
                <div className='Contenido-Grid-NuevadireccionRecoleccion'>
                    <div className='MapaNueva'>
                        <MapaclientePeticion
                            Direccion={ResumenPeticion}
                            AccionCambioLat={HanlderCambioLatitudeNueva}
                            AccionCambioLng={HanlderCambioLongitudeNueva}
                            AccionEditar={HanlderEditarNuevaDireccion}
                        ></MapaclientePeticion>
                    </div>
                    <div className='Nuevadireccion'>
                        <div className="containerDatosResumen">
                            <div className='ContainerOrigenResumen'>
                                <h4>Origen:</h4>
                                <p>{OrigenSelecionado}</p>
                            </div>
                            <div className='ContainerDireccionResumen'>
                                <h4>Dirección:  </h4>
                                <p>{`${DireccionSeleccionada?.calle} ${DireccionSeleccionada?.noint === "s/n" ? DireccionSeleccionada?.noext : DireccionSeleccionada?.noext}, ${DireccionSeleccionada?.colonia}, ${DireccionSeleccionada?.cp}, ${DireccionSeleccionada?.localidad}, ${DireccionSeleccionada?.estado}., ${DireccionSeleccionada?.pais}`}</p>
                            </div>
                        </div>
                        <div className='ContainerCheckAddressButton'>
                            <div className='Boton_Aceptar'>
                                <button disabled={disable} style={disable === true ? { background: '#94D48B' } : { background: '#3BAF29', cursor: 'pointer' }} onClick={() => HanlderSubmitPeticion()}>Crear petición</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    // CUERPO MODAL MERCANCIA PETICIONES
    const RenderItemMercancia = () => {
        return (
            <div>
                <div className="containerimgMercanciaPeticion">
                    <div className={TipoDetalles === 1 ? `ContenedorImagenActivo` : `ContenedorImagen`} onClick={() => HanlderTipodetalle(1)}>
                        <img src={imgCaja} />
                    </div>
                    <div className={TipoDetalles === 0 ? `ContenedorImagenActivo` : `ContenedorImagen`} onClick={() => HanlderTipodetalle(0)}>
                        <img src={imgSobre} />
                    </div>
                    <div className={TipoDetalles === 2 ? `ContenedorImagenActivo` : `ContenedorImagen`} onClick={() => HanlderTipodetalle(2)}>
                        <img src={imgTarima} />
                    </div>
                </div>

                <div className='containerInfoInputs'>
                    <InputComponent
                        nombre={"Cantidad"}
                        tipo={"Text"}
                        flex={1}
                        Placeholder={"Ingrese la cantidad a enviar..."}
                        Accion={HandlerCantidad}
                        value={Cantidad}
                    ></InputComponent>
                    <InputComponent
                        nombre={"Contenido"}
                        tipo={"Text"}
                        flex={2}
                        Placeholder={"Ingrese contenido del paquete ..."}
                        Accion={HanlderContenido}
                        value={Contenido}
                    ></InputComponent>
                </div>

                <div className='containerInfoInputs'>
                    <InputComponent
                        nombre={"Largo"}
                        tipo={"Text"}
                        flex={2}
                        Placeholder={"cm"}
                        Accion={HanlderLargo}
                        value={TipoDetalles === 0 ? 0 : Largo}
                    ></InputComponent>
                    <InputComponent
                        nombre={"Ancho"}
                        tipo={"Text"}
                        flex={2}
                        Placeholder={"cm"}
                        Accion={HandlerAncho}
                        value={TipoDetalles === 0 ? 0 : Ancho}
                    ></InputComponent>
                    <InputComponent
                        nombre={"Alto"}
                        tipo={"Text"}
                        flex={2}
                        Placeholder={"cm"}
                        Accion={HanlderAlto}
                        value={TipoDetalles === 0 ? 0 : Alto}
                    ></InputComponent>
                    <InputComponent
                        nombre={"Peso"}
                        tipo={"Text"}
                        flex={2}
                        Placeholder={"kg"}
                        Accion={HanlderPeso}
                        value={Peso}
                    ></InputComponent>
                </div>
                <div className='ContainerCheckAddressButton' style={{ marginTop: '15px' }}>
                    <div className='Boton_Aceptar'>
                        <button disabled={disable} style={disable === true ? { background: '#94D48B' } : { background: '#3BAF29', cursor: 'pointer' }} onClick={() => HanlderAgregarDetalle()} >Aceptar</button>
                    </div>
                </div>
            </div>
        )
    }

    const HanlderLimpiarDetalles = async () => {
        await HandlerCantidad(undefined)
        await HanlderContenido(undefined)
        await HanlderLargo(undefined)
        await HandlerAncho(undefined)
        await HanlderAlto(undefined)
        await HanlderPeso(undefined)
    }
    const HanlderResumenPeticion = () => {
        setResumenPeticion(
            {
                lat: DireccionSeleccionada?.coordenadas.latitude,
                lng: DireccionSeleccionada?.coordenadas.longitude,
            }
        )
        AbrirModalResumenPeticiones()
    }
   
    const HanlderAgregarDetalle = () => {
        setdisable(true)
        let count = NuevaMercancia + 1
        setNuevaMercancia(count)

        let data = {
            "id": count,
            "norastreo": "02-051138633571",
            "idalianza": 2,
            "nombrealianza": "PAQUETEXPRESS",
            "cantidad": Cantidad,
            "contenido": Contenido,
            "tipo": TipoDetalles,
            "largo": Largo,
            "ancho": Ancho,
            "alto": Alto,
            "peso": Peso
        }
        HandlerSelectMercanciaOS(count)
        HanlderTipoDetalle("Mercancia")
        HanlderUpdateDatosDetalles([...DatosDetalles, data])
        HanlderLimpiarDetalles()
        CerrarModalMercanciaPeticion()
    }
    const EliminarMercancia = (id) => {
        const remoeveemb = DatosDetalles.filter(datos => datos.id != id)
        HanlderUpdateDatosDetalles(remoeveemb)

    }
  
    return (
        LoadingAñadirnueva ?
            <>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                    <Loader
                        title="Añadiendo petición"
                    ></Loader>
                </div>
            </>
            :
            <PeticionesLayout
                colorAzul={Darkmode === false ? AzulFuerteLight : 'red'}
                Blanco={Darkmode === false ? Blanco : 'red'}
            >

                <div style={VistaNuevoCliente === false ? { display: 'block' } : { display: 'none' }} className='ContainerNuevo'>
                    <div className='bodynuevo' style={dimension <= 1400 ? { height: '78vh' } : { height: '85vh' }}>
                        <div className="InfoCliente-Nuevo">
                            <div className='container-InfoCliente-Nuevo' style={{ height: '12%' }}>
                                <div className='ContainerTitle-InfoCliente-Nuevo'>
                                    <h3>Datos de petición</h3>
                                </div>
                            </div>
                            <div className='body-InfoCliente-Nuevo'>
                                <div className='container-Origen-InfoCliente-Nuevo'>
                                    <div style={{ width: '85%', margin: 'auto', paddingLeft: '4px', marginBottom: '10px' }}>
                                        <Slider {...settings}>
                                        {/* NumIntNueva === undefined ? '' : NumIntNueva, */}
                                            <div className={`imgTipoPeticion ${TipoPeticionSeleccionado === 'Recoleccion' || TipoPeticionSeleccionado === undefined ? "activeTipoPeticion" : ""}`} onClick={(e) => HanlderTipoPeticion(e, "RECOLECCION")}>
                                                <img style={{ marginTop: '6px' }} src={TipoPeticionSeleccionado === 'Recoleccion' || TipoPeticionSeleccionado === undefined ? imgRecoleccionActivo : imgRecoleccion} />
                                                <p style={TipoPeticionSeleccionado === 'Recoleccion' || TipoPeticionSeleccionado === undefined ? { marginTop: '7px', color: '#ffff' } : { marginTop: '7px' }}>Recolección</p>
                                            </div>
                                            <div className='imgTipoPeticion'>
                                            {/* <div className={`imgTipoPeticion ${TipoPeticionSeleccionado === 'Entrega' ? "activeTipoPeticion" : ""}`} onClick={(e) => HanlderTipoPeticion(e, "ENTREGA")}>*/}
                                                <img style={{ marginTop: '4px' }} src={TipoPeticionSeleccionado === 'Entrega' ? imgEntregaActivo : imgEntrega} />
                                                <p style={TipoPeticionSeleccionado === 'Entrega' ? { color: '#ffff' } : { color: '#333333' }}>Entrega</p>
                                            </div> 
                                            <div className='imgTipoPeticion'>
                                                <img style={{ marginTop: '4px' }} src={TipoPeticionSeleccionado === 'Cobranza' ? imgCobranzaActivo : imgCobranza} />
                                                <p style={TipoPeticionSeleccionado === 'Cobranza' ? { color: '#ffff' } : { color: '#333333' }}>Cobranza</p>
                                            </div>
                                            <div className='imgTipoPeticion'>
                                                <img src={TipoPeticionSeleccionado === 'Banco' ? imgBancoActivo : imgBanco} />
                                                <p style={TipoPeticionSeleccionado === 'Banco' ? { color: '#ffff' } : { color: '#333333' }}>Banco</p>
                                            </div>
                                            <div className='imgTipoPeticion'>
                                                <img style={{ marginTop: '4px' }} src={TipoPeticionSeleccionado === 'Otro' ? imgOtroActivo : imgOtro} />
                                                <p style={TipoPeticionSeleccionado === 'Otro' ? { color: '#ffff' } : { color: '#333333' }}>Otro</p>
                                            </div>
                                            <div className={`imgTipoPeticion ${TipoPeticionSeleccionado === 'Recoleccion' || TipoPeticionSeleccionado === undefined ? "activeTipoPeticion" : ""}`} onClick={(e) => HanlderTipoPeticion(e, "RECOLECCION")}>
                                                <img style={{ marginTop: '6px' }} src={TipoPeticionSeleccionado === 'Recoleccion' ? imgRecoleccionActivo : imgRecoleccion} />
                                                <p style={TipoPeticionSeleccionado === 'Recoleccion' ? { marginTop: '7px', color: '#ffff' } : { marginTop: '7px' }}>Recolección</p>
                                            </div>
                                            <div className='imgTipoPeticion'>
                                                <img style={{ marginTop: '4px' }} src={TipoPeticionSeleccionado === 'Entrega' ? imgEntregaActivo : imgEntrega} />
                                                <p style={TipoPeticionSeleccionado === 'Entrega' ? { color: '#ffff' } : { color: '#333333' }}>Entrega</p>
                                            </div>
                                            <div className='imgTipoPeticion'>
                                                <img style={{ marginTop: '4px' }} src={TipoPeticionSeleccionado === 'Cobranza' ? imgCobranzaActivo : imgCobranza} />
                                                <p style={TipoPeticionSeleccionado === 'Cobranza' ? { color: '#ffff' } : { color: '#333333' }}>Cobranza</p>
                                            </div>
                                        </Slider>
                                    </div>

                                    <div className='Container-BuscadorCliente-InfoCliente-Nuevo' style={dimension <= 1400 ? { height: '20%', marginLeft: '20px' } : { height: '20%', marginLeft: '20px' }}>
                                        <InputComponent
                                            nombre={"Buscar cliente"}
                                            tipo={"Buscador"}
                                            Accion={HanlderClienteBuscador}
                                            Accionbuscador={CargarClientesPaginador}
                                            flex={2}
                                            value={ClienteBuscador}
                                            valor={ClienteBuscador}
                                            Placeholder={"Escribe nombre cliente ..."}
                                            AccionAbrirmodal={AbrirModalClientePeticiones}
                                            CargarClientebuscador={CargarClientesPaginador}
                                        ></InputComponent>
                                        <InputComponent
                                            nombre={"Teléfono"}
                                            tipo={"Text"}
                                            flex={1}
                                            // Placeholder={"Ingrese número telefónico ..."}
                                            Accion={HanlderCambioTelefonoPeticion}
                                            value={TelefonoPeticion}
                                            Disabled={true}
                                            Background={"#efeff4"}
                                        ></InputComponent>
                                    </div>
                                    <div className='ContainerInfoClienteInputs' style={dimension <= 1400 ? { height: '20%', marginLeft: '20px' } : { height: '20%', marginLeft: '20px' }}>
                                        <InputComponent
                                            nombre={"Dirección"}
                                            tipo={"Text"}
                                            flex={1}
                                            // Placeholder={ClienteSeleccionado === undefined ? '' : `${CallePeticion}, ${CiudadPeticion}, ${ColoniaPeticion}`}
                                            Accion={handleChangeDireccion}
                                            //value={ClienteSeleccionado === undefined ? '' : `${CallePeticion} ${NumeroPeticion}, ${ColoniaPeticion}, ${CiudadPeticion}`}
                                            value={CallePeticion === '' || NumeroPeticion === '' || ColoniaPeticion === '' || CiudadPeticion === '' ? '' : `${CallePeticion} ${NumeroPeticion}, ${ColoniaPeticion}, ${CiudadPeticion}`}
                                            Disabled={true}
                                            Background={"#efeff4"}
                                        ></InputComponent>
                                    </div>

                                    <div className='ContainerInfoClienteInputs' style={dimension <= 1400 ? { height: '20%', marginLeft: '20px' } : { height: '20%', marginLeft: '20px' }}>
                                        <InputComponent
                                            nombre={"C. P."}
                                            tipo={"Text"}
                                            flex={1}
                                            // Placeholder={"Ingrese código postal ..."}
                                            Accion={HandlerCambioCodigoposta}
                                            value={Codigopostal}
                                            Disabled={true}
                                            Background={"#efeff4"}
                                        ></InputComponent>
                                        <InputComponent
                                            nombre={"Estado"}
                                            tipo={"Text"}
                                            flex={1}
                                            // Placeholder={"Ingrese estado ..."}
                                            Accion={HanlderCambioEstadoPeticion}
                                            value={EstadoPeiticion}
                                            Disabled={true}
                                            Background={"#efeff4"}
                                        ></InputComponent>
                                        <InputComponent
                                            nombre={"País"}
                                            tipo={"Text"}
                                            flex={1}
                                            // Placeholder={"Ingrese pais ..."}
                                            Accion={HandlerCambioPaisPeticion}
                                            value={PaisPeticion}
                                            Disabled={true}
                                            Background={"#efeff4"}
                                        ></InputComponent>
                                    </div>
                                    <div className='ContainerInfoClienteInputs' style={dimension <= 1400 ? { height: '20%', marginLeft: '20px' } : { height: '20%', marginLeft: '20px' }}>

                                        <InputComponent
                                            nombre={"Referencias"}
                                            tipo={"Text"}
                                            flex={1}
                                            Placeholder={"Ingrese referencia ..."}
                                            Accion={HanlderCambioReferenciaPeticion}
                                            value={ReferenciaPeticion}
                                        ></InputComponent>
                                    </div>
                                </div>

                                {/* <InputBuscador></InputBuscador> */}
                            </div>
                        </div>
                        <div className="InfoMercancia-Nuevo">
                            {
                                SelectMercanciaOnsite.length != 0 || DatosDetalles.length != 0 ?
                                    <>
                                        <div className='tituloMerca'>
                                            <div className="imgmercancia" onClick={() => HanlderShowMercancioa()}>
                                                <img src={ShowMercancia === true ? imgFlechaOff : imgFlecha} alt="" />
                                            </div>
                                            <div style={{ width: '75%', height: '6vh', display: 'flex', alignItems: 'center' }} onClick={() => HanlderShowMercancioa()}>
                                                <h4>Mercancía</h4>
                                            </div>
                                            <div className="contenedorAcciones">
                                                <div onClick={() => AbrirModalMercanciaPeticion()} >
                                                    <NuevoIcon width={27} height={27}></NuevoIcon>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={
                                            {
                                                width: '98% !important',
                                                margin: 'auto',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                // transition: '0.5s',
                                                opacity: ShowMercancia === true && DatosDetalles.length > 0 ? 1 : 0,
                                                height: ShowMercancia === true && DatosDetalles.length > 0 ? '46%' : '0%',
                                            }
                                        }

                                            className='MercanciaContenedorPeticiones'>
                                            <TablaSinPaginador
                                                Cabezera={RenderitemINFOCabezeraMercanciaOnsite}
                                                Cuerpo={RenderitemINFOMercanciaOnsite}
                                            >

                                            </TablaSinPaginador>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div style={{ paddingTop: '10px' }} className='ContenedorFlexSinmercancia'>
                                            <div className='ButonSinmercancia' onClick={() => AbrirModalMercanciaPeticion()}>
                                                <div style={{ paddingTop: '4px' }} className="imgmercancia">
                                                    {/* <img src={imgFlecha} alt="" /> */}
                                                    <NuevoIcon width={25} height={25}></NuevoIcon>
                                                </div>
                                                <div>
                                                    <h4>Añadir mercancía</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                            }
                            <div className="Info-Recolectores">
                                <div className="containerRecolectores">
                                    <h3>Recolectores disponibles</h3>
                                    <div className="cotainerRecolector">
                                        {
                                            RecolectoresMapa.map(resReco => {
                                                if (resReco.cityId == JSON.parse(localStorage.getItem("Ciudad")).idciudad)
                                                    return (
                                                        <div key={resReco.id} className="recolectores"
                                                            onClick={() => HanlderSelecionarRecolector(resReco.id)}
                                                            style={{
                                                                cursor: 'pointer',
                                                                backgroundColor:  Recolector && Recolector[0] === resReco.id ? '#3BAF29' : 'white',
                                                                borderRadius:'7px'
                                                            }}>
                                                            <div className='imgrecolector'>
                                                                <img src={AvatarNuevo} alt="" />
                                                            </div>
                                                            <div className='nombreRecolector'>
                                                                <p style={{color:  Recolector && Recolector[0] === resReco.id ? '#ffff' : '#333333'}}>{resReco.name}</p>
                                                            </div>
                                                            {/* <div className="asignar">
                                                                <img src={Recolector === resReco.id ? imgAsignar : imgSinAsignar} alt="" />
                                                            </div> */}
                                                        </div>
                                                    )
                                            })
                                        }
                                        <div className="recolectores" onClick={() => HanlderSelecionarRecolector("0")}
                                          style={{
                                            cursor: 'pointer',
                                            backgroundColor:  Recolector && Recolector[0] === "0" ? '#3BAF29' : 'white',
                                            borderRadius:'7px'
                                        }}>
                                            <div className='imgrecolector'>
                                                <img src={AvatarNuevo} alt="" />
                                            </div>
                                            <div className='nombreRecolector'>
                                                <p style={{color:  Recolector && Recolector[0] === "0" ? '#ffff' : '#333333'}}>Pendiente</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='ContainerCheckAddressButton'>
                                        <div className='Boton_Aceptar'>
                                            <button onClick={() => HanlderResumenPeticion()} >Aceptar</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>


                <NuevoClienteLayout>

                    <div id="ContenedorPrincipal" style={VistaNuevoCliente === false ? { display: 'none' } : { display: 'block' }}>
                        <div className="EncabezadoNvoCte">
                            <p>Nuevo Cliente</p>
                        </div>
                        <div className="ContenedorGeneral" >
                            <div className="ContenedorMapa">
                                <div>
                                    <MapaclientePeticion
                                        Direccion={GeocoderData}
                                        AccionCambioLat={HanlderCambioLatitudeNueva}
                                        AccionCambioLng={HanlderCambioLongitudeNueva}
                                        AccionEditar={HanlderEditarNuevaDireccion}
                                    ></MapaclientePeticion>
                                </div>
                            </div>
                            <div className="CuerpoNvoCte">
                                {/* <div className="InfoCteNvoInputs" onClick={() => LimpiarNueva()}> */}
                                <div className="InfoCteNvoInputs">
                                    <InputComponent
                                        nombre={"Nombre / Razón social"}
                                        tipo={"Text"}
                                        flex={1}
                                        Accion={handlerNombreCliente}
                                        value={nombreCliente}
                                    ></InputComponent>
                                    <InputComponent
                                        nombre={"Teléfono"}
                                        tipo={"Text"}
                                        flex={1}
                                        Accion={HandlerCambioTelefonoNueva}
                                        value={TelefonoNueva}
                                        MaxLength={10}
                                    ></InputComponent>
                                </div>
                                <div className="InfoCteNvoInputs">
                                    {/* <div className="regimenfiscalSelect">
                                <p>Régimen fiscal</p>
                                <select
                                    value={selectedregimenfiscal}
                                    onChange={(e) => HandlerCambiarRegimen(e)}
                                    style={{
                                        backgroundImage: flechaabajo,
                                        cursor: 'pointer'
                                    }}
                                    name="selectedFruit">
                                    {
                                        DataRegimenF?.map(res => {
                                            // console.log(res.description, "DESCRIPPP")
                                            return (
                                                <option value={res.description} key={res.key}>{res.description}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div> */}
                                </div>
                                <div className="InfoCteNvoInputs" >
                                    <InputComponent
                                        nombre={"Buscar por código postal"}
                                        tipo={"CodigoPostal"}
                                        flex={1}
                                        datos={Datacp}
                                        BuscarCp={CargarCodigoPostales}
                                        AccionCargaCp={HandlerPrecargaCodigopostalNueva}
                                        Loading={LoadingCp}
                                    ></InputComponent>
                                    {/* <InputComponent
                                nombre={"País"}
                                tipo={"Text"}
                                flex={1}
                                Accion={HandlerCambioPaisNueva}
                                value={PaisNueva}
                            ></InputComponent> */}
                                    <div style={{ display: 'flex', flex: '1', flexDirection: 'column', marginRight: '15px' }}>
                                        <p style={{ marginBottom: '0px' }}>País</p>
                                        <div className="InputPais">
                                            <div className="bandera">
                                                <img src={banderaMX} />
                                            </div>
                                            <div className="nombrePais">
                                                <p style={{ fontWeight: 'lighter' }}>México</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="InfoCteNvoInputs">
                                    <InputComponent
                                        nombre={"Calle"}
                                        tipo={"Text"}
                                        flex={1}
                                        Accion={HandlerCambioCalleNueva}
                                        value={CalleNueva}
                                    ></InputComponent>
                                    <InputComponent
                                        nombre={"Núm. Ext."}
                                        tipo={"Text"}
                                        flex={1}
                                        Accion={HandlerCambioNumeroExtNueva}
                                        value={NumeroExtNueva}
                                    ></InputComponent>
                                    <InputComponent
                                        nombre={"Núm. Int."}
                                        tipo={"Text"}
                                        flex={1}
                                        Accion={HandlerCambioNumIntNueva}
                                        value={NumIntNueva}
                                    ></InputComponent>
                                </div>
                                <div className="InfoCteNvoInputs">
                                    <InputComponent
                                        nombre={"C. P."}
                                        tipo={"Text"}
                                        flex={1}
                                        Accion={HandlerCambioCpNueva}
                                        value={CpNueva}
                                    ></InputComponent>
                                    <InputComponent
                                        nombre={"Colonia"}
                                        tipo={"Text"}
                                        flex={1}
                                        Accion={HandlerCambioColoniaNueva}
                                        value={ColoniaNueva}
                                    ></InputComponent>
                                </div>
                                <div className="InfoCteNvoInputs">
                                    <InputComponent
                                        nombre={"Ciudad"}
                                        tipo={"Text"}
                                        flex={1}
                                        Accion={HandlerCambioCiudadNueva}
                                        value={CiudadNueva}
                                    ></InputComponent>
                                    <InputComponent
                                        nombre={"Municipio"}
                                        tipo={"Text"}
                                        flex={1}
                                        Accion={HandlerCambioMunicipioNueva}
                                        value={MunicipioNueva}
                                    ></InputComponent>
                                    <InputComponent
                                        nombre={"Estado"}
                                        tipo={"Text"}
                                        flex={1}
                                        Accion={HandlerCambioEstadoNueva}
                                        value={EstadoNueva}
                                    ></InputComponent>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                            <div style={{ width: '50%' }}></div>
                            <div style={{ height: '75px', width: '50%' }} className="btnAceptar">
                                {/* <button onClick={(e) => handlerNuevoCliente(e)}>Aceptar</button> */}
                                <button style={{ marginTop: '10px' }} onClick={GeocoderData === undefined ? () => HanlderVerificarNueva() : () => handlerNuevoCliente()}>{GeocoderData === undefined ? 'Verificar' : 'Guardar'}</button>
                            </div>
                        </div>
                    </div>
                </NuevoClienteLayout>

                <ModalComponent
                    Nombremodal={ModalClientePeticion}
                    Cerrarmodal={CerrarModalClientePeticiones}
                    Titulo={"Clientes"}
                    Contenido={RenderItem}
                >

                </ModalComponent>

                <ModalComponent
                    Nombremodal={ModalDireccion}
                    Cerrarmodal={CerrarModalDirecciones}
                    Titulo={"Direcciones"}
                    Contenido={RenderItemDireccion}
                >

                </ModalComponent>

                <ModalComponent
                    Nombremodal={ModalSinDireccion}
                    Cerrarmodal={CerrarModalSinDirecciones}
                    Titulo={"Sin dirección de recolección"}
                    Contenido={RenderItemSinDireccion}
                >
                </ModalComponent>
                <ModalComponent
                    Nombremodal={ModalOrigenPeticiones}
                    Cerrarmodal={CerrarModalOrigenPeticiones}
                    Titulo={"Selecciona origen"}
                    Contenido={RenderItemOrigenPeticion}
                >
                </ModalComponent>

                <ModalComponent
                    Nombremodal={ModalDetallesOnsitePeticiones}
                    Cerrarmodal={CerrarModalDatosOnsitePeticiones}
                    Titulo={"Mercancía OnSite"}
                    Contenido={RenderItemDatosOnsite}
                >
                </ModalComponent>

                <ModalComponent
                    Nombremodal={ModalNuevaDrireccionRecolecion}
                    Cerrarmodal={CerrarNuevaDireccionRecoleccion}
                    Titulo={"Nueva Dirección"}
                    Contenido={RenderItemNuevaDireccion}
                ></ModalComponent>


                <ModalComponent
                    Nombremodal={ModalResumenPeTicion}
                    Cerrarmodal={CerrarModalResumenPeticiones}
                    Titulo={"Resumen de Petición"}
                    Contenido={RenderItemResumenPeticion}
                ></ModalComponent>

                <ModalComponent
                    Nombremodal={ModalMercanciaPeticion}
                    Cerrarmodal={CerrarModalMercanciaPeticion}
                    Titulo={"Detalles"}
                    Contenido={RenderItemMercancia}
                ></ModalComponent>
            </PeticionesLayout>
    )
}

export default Nuevapeticion