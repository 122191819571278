import React from 'react'

const IconoPaginadorUltimo =() => {
  return (
    <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 24L20 13L12 2" stroke="#40A859" strokeWidth="4" stroke-linecap="round" strokeLinejoin="round"/>
<path d="M2 24L10 13L2 2" stroke="#40A859" strokeWidth="4" stroke-linecap="round" strokeLinejoin="round"/>
</svg>

  )
}

export default IconoPaginadorUltimo